import { useState, useEffect } from 'react';
import axios from 'axios';

const apiUrl = "https://15i9qvt2ab.execute-api.us-east-1.amazonaws.com/prod/tormenta";

const useArticles = (folder) => {
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Function to parse XML and fetch article list
        const fetchArticlesList = async (folder) => {
            try {
                const url = `${apiUrl}/${folder}`;
                const response = await axios.get(url);
                const xmlData = response.data;

                const parser = new DOMParser();
                const xmlDoc = parser.parseFromString(xmlData, "application/xml");
                return [...xmlDoc.querySelectorAll("Contents Key")]
                    .map(element => element.textContent)
                    .filter(key => key.endsWith('.json'));
            } catch (error) {
                console.error("Error fetching articles list:", error);
                setError(error);
                throw error;
            }
        };

        // Function to fetch individual article data
        const fetchArticleData = async (apiUrl, folder, articleKey) => {
            const jsonUrl = `${apiUrl}/${folder}/${articleKey}`;
            const mdUrl = jsonUrl.replace('.json', '.md');

            try {
                const jsonResponse = await axios.get(jsonUrl);
                const data = jsonResponse.data;
                const mdContentResponse = await axios.get(mdUrl);
                const mdContent = mdContentResponse.data;

                return {
                    id: data.id,
                    title: data.title,
                    date: data.date,
                    type: "turismo",
                    imageUrl: data.imageUrl,
                    content: mdContent,
                    description: data.description
                };
            } catch (error) {
                throw error;
            }
        };

        // Function to initiate loading all articles
        const loadArticles = async () => {
            try {
                const articleKeys = await fetchArticlesList(folder);
                const articlePromises = articleKeys.map(key => {
                    // Remove the folder from the key if present
                    const formattedKey = key.replace(`${folder}/`, '');
                    return fetchArticleData(apiUrl, folder, formattedKey);
                });
                const articles = await Promise.all(articlePromises);
                setArticles(articles);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        loadArticles();
    }, [folder]); // Effect dependencies

    return { articles, loading, error };
};

export default useArticles;
