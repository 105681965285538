import React from 'react';
import heroImage from '../img/hero.png'; // Ensure the path is correct
function HeroSection() {

    document.body.style.overflow = "hidden";
    return (
        <div className="hero-section menu glitch">
            <img src={heroImage} alt="Central Hero" class="hover-expand"/>
            <h1 className='title'>Tormenta</h1>
        </div>
    );
}

export default HeroSection;