import React from 'react';

function Agenda() {
    return (

        <iframe 
            id='calendar'
            src="https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=Europe%2FLisbon&bgcolor=%23ffffff&src=cGVkcm9ncmFjaW9AZGVhZGNvdy5lbnRlcnByaXNlcw&src=YWRkcmVzc2Jvb2sjY29udGFjdHNAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&src=ODZhcDNuY3AzajluaWFvczJxOGUwNTB1b3ZqM3JlczFAaW1wb3J0LmNhbGVuZGFyLmdvb2dsZS5jb20&color=%23039BE5&color=%2333B679&color=%23E4C441" 
            width="80%" 
            height="600" 
            style={{margin: "120px", zIndex: 1000 }} 
            frameborder="0" 
            scrolling="yes">
        </iframe>

    );
}

export default Agenda;