import React from 'react';
import { useNavigate } from 'react-router-dom';
import option4Image from '../img/option4-removebg-preview.png';
import option5Image from '../img/option5-removebg-preview.png';
import option6Image from '../img/option6-removebg-preview.png';

function RightMenu() {
    const navigate = useNavigate();

    const navigateToBlog = (option) => {
        navigate(`/blog?type=${option}`);
    }

    return (
        <div className="menu vertical-menu">
            <button className="transparent-btn" onClick={() => navigateToBlog('musica')}>
                <img src={option4Image} alt="Option 4" class="hover-expand"/>
            </button>
            <button className="transparent-btn" onClick={() => navigateToBlog('gastronomia')}>
                <img src={option5Image} alt="Option 5" class="hover-expand"/>
            </button>
            <button className="transparent-btn" onClick={() => navigateToBlog('turismo')}>
                <img src={option6Image} alt="Option 6" class="hover-expand"/>
            </button>
        </div>
    );
}

export default RightMenu;
