import React from 'react';
import { useParams, useLocation} from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import useArticles from './useArticles';


function BlogPostDetail() {
    
    const { postId } = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
  
    // access query parameters
    const paramValue = queryParams.get("type");

    const { articles} = useArticles(paramValue);

    const filteredPosts = articles;

    
    const post = filteredPosts.find(p => p.id == postId);
    document.body.style.overflow = "auto";
    
    if (!post) {
        return <div></div>;
    }

    return (
        <article className="blog-post-detail">
            <ReactMarkdown breaks={true}>
                {post.content}
            </ReactMarkdown>
        </article>
    );
}

export default BlogPostDetail;