import React from 'react';
import { useNavigate } from 'react-router-dom';
import option1Image from '../img/option1-removebg-preview.png';
import option2Image from '../img/option2-removebg-preview.png';
import option3Image from '../img/option3-removebg-preview.png';

function LeftMenu() {
    const navigate = useNavigate();

    const navigateToBlog = (option) => {
        navigate(`/blog?type=${option}`);
    }

    return (
        <div className="menu vertical-menu">
            <button className="transparent-btn" onClick={() => navigateToBlog('literatura')}>
                <img src={option1Image} alt="Option 1" class="hover-expand"/>
            </button>
            <button className="transparent-btn" onClick={() => navigateToBlog('belas-artes')}>
                <img src={option2Image} alt="Option 2" class="hover-expand"/>
            </button>
            <button className="transparent-btn" onClick={() => navigateToBlog('teatro')}>
                <img src={option3Image} alt="Option 3" class="hover-expand"/>
            </button>
        </div>
    );
}

export default LeftMenu;