import React from 'react';
import { Link } from 'react-router-dom';

function Navbar() {
    return (
        <nav className="navbar">
            <ul className="navbar-links">
                <li><Link to="/">home</Link></li>
                <li><Link to="/agenda">agenda</Link></li>
                <li><Link to="/radio">radio</Link></li>
                <li><Link to="/loja">loja</Link></li>
            </ul>
        </nav>
    );
}

export default Navbar;