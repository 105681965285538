import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import './App.scss';
import LeftMenu from './components/LeftMenu';
import Navbar from './components/NavBar';
import RightMenu from './components/RightMenu';
import HeroSection from './components/HeroSection';
import BottomMenu from './components/BottomMenu';
import Agenda from './components/Agenda';
import BlogPage from './components/BlogPage';
import BlogPostDetail from './components/BlogPostDetail';

function App() {
    return (
        <main>
            <div className="noise"></div>
            <div className="wrapper">
                <Router>
                    <div className="App" id="app">
                        <Navbar />
                        <Routes> 
                            <Route path="/agenda" element={<Agenda />} />
                            <Route path="/blog" element={<BlogPage />} />
                            <Route path="/blog/:postId" element={<BlogPostDetail />} />
                            <Route
                                path="/"
                                element={
                                    <div className="focus-overlay">
                                        <LeftMenu />
                                        <HeroSection />
                                        <RightMenu />
                                        <BottomMenu />
                                    </div>
                                }
                            />
                        </Routes>
                    </div>
                </Router>
            </div>
        </main>
    );
}

export default App;
