import React, { useRef, useState, useEffect } from 'react';
import RandomColorText from './RandomColorText';
import {  useNavigate } from 'react-router-dom';


function BottomMenu() {
    // const navigate = useNavigate();
    // const imgRefs = useRef([]);
    // document.body.style.overflow = "auto";
    // // State for storing image widths, initialized as an array to hold widths for each post.
    // const [imgWidths, setImgWidths] = useState([]);
    // useEffect(() => {
    //     // Map over imgRefs to set image widths for all refs that are currently set.
    //     setImgWidths(imgRefs.current.map(img => img ? img.offsetWidth : 0));
    // }, []);

    return (
        <div className='footer'>
                    <img src={ "/footer.jpg"}></img>
        </div>
    );

    // return (
    //     <div className="bottom-menu">
    //         {blogPosts.slice(0, 3).map((post, index) => (
    //             <div key={post.id}  className="post-card" onClick={() => navigate(`/blog/${post.id}`)}>
    //                 <RandomColorText text={post.title} width={imgWidths[index] || 0}/>
    //                 <br />
    //                 <div className='glitch'>
    //                 <div className='noise'> </div>
    //                 <img ref={el => imgRefs.current[index] = el} src={post.imageUrl} alt={`Description of ${post.title}`} />
    //                 </div>
    //                 <h5>{post.description}</h5>

    //             </div>
    //         ))}
    //     </div>
    // );
}

export default BottomMenu;

