import React, { useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import useArticles from './useArticles';


function BlogPage() {


    const [searchParams] = useSearchParams();
    const navigate = useNavigate(); // Use navigate here
    const blogType = searchParams.get('type');
    //const filteredPosts = blogPosts.filter(post => post.type === blogType);

    const { articles} = useArticles(blogType);

    const filteredPosts = articles;

    const [currentIndex, setCurrentIndex] = useState(0);

    const handlePrev = () => {
        const newIndex = currentIndex > 0 ? currentIndex - 1 : filteredPosts.length - 1;
        setCurrentIndex(newIndex);
    };

    const handleNext = () => {
        const newIndex = currentIndex < filteredPosts.length - 1 ? currentIndex + 1 : 0;
        setCurrentIndex(newIndex);
    };

    const goToBlogPost = (postId, type) => {
        navigate(`/blog/${postId}?type=${type}`); // Navigate to blog post details page
    };

    const handlers = useSwipeable({
        onSwipedLeft: () => handleNext(),
        onSwipedRight: () => handlePrev(),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    return (
        <div  {...handlers} className="blog-page">
            {filteredPosts.length > 0 && (
                <div className="blog-post" onClick={() => goToBlogPost(filteredPosts[currentIndex].id, filteredPosts[currentIndex].type)}>
                    <div className="image-container">
                       
                        <div className='container'>
                        <button className="arrow left" onClick={(e) => { e.stopPropagation(); handlePrev(); }}>
                            <i className="fa fa-arrow-left"></i>
                        </button>
                        <div className="video-overlay">
                        <img src={filteredPosts[currentIndex].imageUrl} alt={filteredPosts[currentIndex].title} />
                        </div>
                        <button className="arrow right" onClick={(e) => { e.stopPropagation(); handleNext(); }}>
                            <i className="fa fa-arrow-right"></i>
                        </button>
                        </div>
                    </div>
                    <div className='footer'>
                <img src={ "/footer.jpg"}>
            </img>
            </div>
                    <h2>{filteredPosts[currentIndex].title}</h2>
                    <p>Posted on {filteredPosts[currentIndex].date}</p>
                    <p>{filteredPosts[currentIndex].description}</p>
                </div>
            )}

        </div>
    );
}

export default BlogPage;